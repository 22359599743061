import React, { useState, useEffect } from "react"
import {useHistory, useLocation, useParams } from "react-router-dom";
import {useSolv} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {MainContainer} from "../components/FormComps"
import {PageDialogContainer, PageDialog, PageDialogMainPanel} from "../components/PageDialog"
import {GoogleButton} from "../components/GoogleButton"
import {LinkedInButton} from "../components/LinkedInButton"
import ReCAPTCHA from "react-google-recaptcha";
import {isBlankString} from "../components/StringUtils";
import {validateEmailAddress} from "../components/ValidationUtils";
import {PageDialogHeader, PageDialogFooter} from "./PageDialogUtils";
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("Signin")

function hashToStep(hash) {
  if (hash) {
    if (hash.startsWith("#")) {
      return hash.slice(1).toUpperCase()
    }
    else {
      return hash.toUpperCase()
    }
  }
  else {
    return null
  }
}

export default function Signin(props) {

  const {api, auth: {signin, generateMagicLink}, env, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const params = useParams()
  const location = useLocation()
  const history = useHistory()

  const queryParams = new URLSearchParams(location.search)

  const [emailAddress, setEmailAddress] = useState(queryParams.get("emailAddress"))
  const [recaptchaResponse, setReCaptchaResponse] = useState(null)
  const [magicCode, setMagicCode] = useState(params.magicCode)
  const [step, setStep] = useState(null)

  const [alert, setAlert] = useState(null)

  useEffect(() => {
    if (magicCode) {
      signinWithMagicCode()
    }
  },[magicCode])

  useEffect(() => {
    setStep(hashToStep(location.hash))
  }, [location])

  const googleHandleSuccessfulLogin = async (response) => {
    let signInData
    try {
      log.debug("googleHandleSuccessfulLogin: response=", response)
      setBusy(intl.msg("working"))
      const payload = {
        idProvider: "GOOGLE",
        profileObj: response.profileObj,
        authToken: response.tokenId
      }
      log.debug("googleHandleSuccessfulLogin: payload=", payload)
      await doSignin(payload)
    }
    catch (err) {
      handleError(err)
    }
    finally {
      setBusy(null)
    }
  }

  const googleHandleFailedLogin = (response) => {
    log.debug("googleHandleFailedLogin: ", response)
    setAlert({error: intl.msg("sign_in_error_google_signin_failed"), response})
  }

  const linkedInHandleSuccessfulLogin = async (response) => {
    let signInData
    try {
      setBusy(intl.msg("working"))
      const payload = {
        idProvider: "LINKEDIN",
        code: response
      }
      await doSignin(payload)
    }
    catch (err) {
      handleError(err)
    }
    finally {
      setBusy(null)
    }
  }

  function handleSigninWithEmailClick() {
    setAlert(null)
    window.location.hash = "#email"
  }

  function handleEmailAddressChange(e) {
    e.preventDefault()
    setEmailAddress(e.target.value)
  }

  async function handleEmailContinueClick() {
    try {
      setBusy(intl.msg("working"))
      if (isBlankString(emailAddress) || !validateEmailAddress(emailAddress)) {
        setAlert({error: intl.msg("error_invalid_input_email_address")})
      }
      else if (!recaptchaResponse) {
        setAlert({error: intl.msg("error_invalid_input_recaptcha")})
      }
      else {
        const payload = {
          op: "SIGN_IN",
          emailAddress: emailAddress,
          recaptcha: recaptchaResponse
        }
        await generateMagicLink(payload)
        setAlert(null)
        queryParams.set("emailAddress", emailAddress)
        window.location.hash = "#check_email"
        window.location.search = queryParams.toString()
      }
    }
    catch (err) {
      handleError(err)
    }
    finally {
      setBusy(null)
    }
  }

  function handleResendVerificationClick() {
    window.location.hash = "#email"
  }

  async function signinWithMagicCode() {
    let signInData
    try {
      setBusy(intl.msg("working"))
      const payload = {
        op: "SIGN_IN",
        idProvider: "MAGICLINK",
        code: magicCode
      }
      setAlert(null)
      await doSignin(payload)
    }
    catch (err) {
      handleError(err)
    }
    finally {
      setBusy(null)
    }
  }

  async function doSignin(payload){
    try {
      log.debug("doSignin: payload=", payload)
      const data = await signin(payload)
      log.debug("doSignin: data=", data)
      gotoDest(data)
    }
    catch (err) {
      log.debug("doSignin: failed: err=", err)
      handleError(err)
    }
  }

  function gotoDest(signInData) {
    if (signInData && signInData.user && !signInData.user.onboardedOn) {
      window.location = "/onboarding"
    }
    else {
      let dest = "/";
      if (props.location && props.location.state && props.location.state.referrer) {
        dest = props.location.state.referrer
        if (dest.pathname) {
          dest = dest.pathname
        }
        else {
          dest = "/"
        }
      }
      window.location = dest
    }
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError", error.code)
      const code = error.code
      switch (code) {
        case "UNAUTHORIZED":
        case "NOT_FOUND":
          setAlert({error: intl.msg("error_unknown_user")})
          break
        case "BANNED_USER":
        case "BANNED_TENANT":
          setAlert({error: intl.msg("error_banned_user")})
          break
        case "BANNED_EMAIL_ADDRESS":
          setAlert({error: intl.msg("error_banned_email_address")})
          break
        case "INVALID_RECAPTCHA":
          setAlert({error: intl.msg("error_invalid_recaptcha")})
          break
        case "INVALID_MAGIC_LINK":
          setAlert({error: intl.msg("error_invalid_magic_link")})
          break
        case "DUPLICATE_MAGIC_LINK":
          setAlert({error: intl.msg("error_duplicate_magic_link")})
          break
        case "INVITING":
          setAlert({error: intl.msg("signin_error_inviting", {a: chunks => <a className="text-link" href={`/signup/${error.details.userId}`}>{chunks}</a>})})
          break
        case "UNAVAILABLE":
          window.location = "/unavailable"
          break
        default:
          setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  return (
    <MainContainer>
      <PageDialogContainer>
        <PageDialog header={PageDialogHeader} footer={PageDialogFooter} size="md">
          {
            ("EMAIL" === step) ? (
              <>
                <PageDialogMainPanel title={intl.msg("signin_with_email_title")} alert={alert}>
                  <p className="mt-3">
                    {intl.msg("signin_with_email_text", {a_recaptcha: chunks => <a className="def-link" href="https://en.wikipedia.org/wiki/ReCAPTCHA" target="_blank">{chunks}</a>})}
                  </p>
                  <input className="form-control" key="inp_emailAddress" id="inp_emailAddress" name="emailAddress" style={{width: "300px", padding: "20px", color: "var(--form-control-text-color)", backgroundColor: "var(--form-control-background-color)", border: "1px solid #555"}} placeHolder={intl.msg("your_email_address_placeholder")} value={emailAddress} onChange={handleEmailAddressChange}/>
                  <div id="fg_captcha" style={{marginTop: "10px"}}>
                    <ReCAPTCHA
                        sitekey={`${env.GOOGLE_RECAPTCHA_SECRET}`}
                        onChange={(e) => setReCaptchaResponse(e)}
                        theme={window.getComputedStyle(document.documentElement).getPropertyValue("--theme")}/>
                  </div>
                  <button className="btn btn-primary mt-3" style={{minWidth: "100px", padding: "8px", marginTop: "10px"}} onClick={handleEmailContinueClick}>
                    {intl.msg("continue")}
                  </button>
                  <div className="mt-3">
                    <a className="text-link" href="/signin">{intl.msg("signin_with_email_use_another_means")}</a>
                  </div>
                </PageDialogMainPanel>
              </>
            ) : ("CHECK_EMAIL" === step) ? (
              <>
                <PageDialogMainPanel title={intl.msg("signin_with_email_check_your_email_title")} alert={alert}>
                  <p className="mt-3">
                    {intl.msg("signin_with_email_check_your_email_text", {brand: "PetSOLV", emailAddress: emailAddress, b: chunks => <strong>{chunks}</strong>, em: chunks => <em>{chunks}</em>, p: chunks  => <p className="mt-3">{chunks}</p>})}
                  </p>
                  <button type="button" className="btn btn-primary mt-3" onClick={handleResendVerificationClick}>{intl.msg("resend")}</button>
                </PageDialogMainPanel>
              </>
            ) : (
              <>
                <PageDialogMainPanel title={intl.msg("signin_title", {brandName: "PetSOLV"})} alert={alert}>
                  <p className="mt-3">
                    {intl.msg("signin_text")}
                  </p>
                  <div className="mt-4">
                    <GoogleButton
                        onSuccess={googleHandleSuccessfulLogin}z
                        onFailure={googleHandleFailedLogin}
                        disabled={false}
                        buttonText={intl.msg("signin_with_google")}/>
                  </div>
                  <div className="mt-1">
                    <LinkedInButton
                        onSuccess={linkedInHandleSuccessfulLogin}
                        buttonText={intl.msg("signin_with_linkedin")}/>
                  </div>
                  <div className="mt-1" style={{flexDirection: "column", alignItems: "center", }}>
                    <button className="btn-signin btn-signin-email" type="button" onClick={handleSigninWithEmailClick}>
                      <div className="btn-signin-icon">
                        <i className="fas fa-envelope"></i>
                      </div>
                      <span className="btn-signin-text">{intl.msg("signin_with_email")}</span>
                    </button>
                  </div>
                  <div className="mt-3">
                    <p style={{fontSize: "8pt"}}>{intl.msg("signin_by_clicking", {a1: chunks => <a href="https://sassets.solv.technology/legal/terms.html" target="_blank">{chunks}</a>, a2: chunks => <a href="https://sassets.solv.technology/legal/privacy.html" target="_blank">{chunks}</a>})}</p>
                  </div>
                  <div className="mt-4" style={{borderTop: "1px solid #555", paddingTop: "20px"}}>
                    {intl.msg("signin_dont_have_an_account")}
                  </div>
                </PageDialogMainPanel>
              </>
            )
          }
        </PageDialog>
      </PageDialogContainer>
    </MainContainer>
  )
}