import React, { useState, useEffect }  from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js'
import { externalTooltipHandler } from "./ChartUtils.js"
import { useSolv } from "./SolvProvider"
import { useIntlEx } from "./IntlUtils.js"
import getLogger from "./Logging.js"
import {Line} from "react-chartjs-2";
import styles from "./Widget.module.css"
import {CreditsWidget} from "../CreditTransactions";
import {mkHref} from "./AccountUtils";

const log = getLogger("Widget")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export function WidgetGroup(props) {
  return (
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
      {props.children}
    </div>
  )
}

export function Widget(props) {
  log.debug("Widget: props=", props)
  let addStyles = {}
  if (props.size) {
    addStyles.width =
        "xl" === props.size ? (
          "400px"
        ) : "lg" === props.size ? (
          "300px"
        ) : "md" === props.size ? (
          "150px"
        ) : (
          "fit-content"
        )
  }
  return (
    <div className={styles.widget + ` ${props.className ? props.className : ""} ${props.onClick ? styles.clickable : ""}`} style={addStyles} onClick={props.onClick ? props.onClick : null}>
      {
        (props.iconClass && props.iconClass.trim()?.length > 0) &&
          <span key={`span-${props.iconClass}`} className={styles.icon}><i key={`fa-${props.iconClass}`} className={props.iconClass}></i></span>
      }
      <div className={styles.title}>{props.title}</div>
      {
        props.subtitle &&
          <div className={styles.subtitle}>
            {props.subtitle}
          </div>
      }
      <div className={styles.body}>
        {props.children}
      </div>
    </div>                    
  )
}

export function WidgetMetric(props) {
  return (
    <div className={styles.metric} title={props.title}>
      {props.children}
    </div>
  )
}

export function WidgetMiniChart(props) {
  return (
    <div className={styles["mini-chart"]}>
      {props.children}
    </div>
  )
}

export function WidgetMiniLineChart(props) {
  
  const [chartOptions, setChartOptions] = useState(null)

  useEffect(() => {
    setChartOptions({
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: externalTooltipHandler
        }              
      },              
      scales: {
        x: {
          display: false,
          beginAtZero: true,
        },
        y: {
          display: false,
          beginAtZero: true,
        },
      }              
    })
  }, [])
  
  return (
    props.data &&
      <div className={styles["mini-chart"]}>
        <Line options={chartOptions} data={props.data}/>    
      </div>
  )
}

export function AccountSummaryWidgets(props) {

  const {api, session} = useSolv()
  const {intl} = useIntlEx()

  // const [tenant, setTenant] = useState(null)
  const [broadcastPerformanceStats, setBroadcastPerformanceStats] = useState(null)
  const [adViewsChartData, setAdViewsChartData] = useState(null)
  const [adClicksChartData, setAdClicksChartData] = useState(null)
  const [feedbacksChartData, setFeedbacksChartData] = useState(null)
  const [publishedChartData, setPublishedChartData] = useState(null)
  const [billedStats, setBilledStats] = useState(null)
  const [billedChartData, setBilledChartData] = useState(null)
  const [incomeStats, setIncomeStats] = useState(null)
  const [incomeChartData, setIncomeChartData] = useState(null)

  useEffect(() => {
    if (props.tenant) {
      // log.debug("AccountSummaryWidgets: isReseller=", props.tenant.isReseller())
      // setTenant(props.tenant)
      loadSummarizedStats(props.tenant)
    }
  },[])

  function loadSummarizedStats(tenant) {

    log.debug("loadSummarizedStats: props=", tenant)

    let url = `/v1/tenants/${tenant.tenantId}/_ninetyDaysStats`

    log.debug("loadSummarizedStats: url=", url)

    return api.get(url)
      .then(({data}) => {

        log.debug("loadSummarizedStats: data=", data.bills)

        if (data.broadcastStats) {
          loadBroadcastPerformanceStats(data.broadcastStats)
        }

        if (data.incomeStats) {
          loadIncomeStats(data.incomeStats)
        }

        if (data.billStats) {
          loadBillingStats(data.billStats)
        }
      })
      .catch((error) => {
        log.debug("Error", error)
      })
  }

  function loadIncomeStats(data) {
    
    let statsData = {
      curr: 0.0,
      last: 0.0,
      prev: 0.0
    }
  
    log.debug("loadIncomeStats: data=", data)    

    if (data) {
      statsData.curr = data.total30Days
      statsData.last = data.total60Days
      statsData.prev = data.total90Days
    }

    log.debug("loadIncomeStats: statsData", statsData)    

    setIncomeStats(statsData)

    setIncomeChartData({
      labels: ["90 Days", "60 Days", "30 Days"],
      datasets: [{
        label: 'Income',
        data: [intl.num(statsData.prev), intl.num(statsData.last), intl.num(statsData.curr)],
        borderColor: "rgb(65,236,155)",
        backgroundColor: "rgb(65,236,155)", 
        borderWidth: 1,
      }]
    })
  }

  function loadBillingStats(data) {
    
    let statsData = {
      curr: 0.0,
      last: 0.0,
      prev: 0.0
    }
    
    log.debug("loadBillingStats: data=")

    if (data) {
      statsData.curr = data.total30Days
      statsData.last = data.total60Days
      statsData.prev = data.total90Days
    }

    log.debug("loadBillingStats: statsData", statsData)    

    setBilledStats(statsData)

    setBilledChartData({
      labels: ["90 Days", "60 Days", "30 Days"],
      datasets: [{
        label: 'Billed',
        data: [intl.num(statsData.prev), intl.num(statsData.last), intl.num(statsData.curr)],
        borderColor: "rgb(65,236,155)",  
        backgroundColor: "rgb(65,236,155)",
        borderWidth: 1,
      }]
    })
  }

  function loadBroadcastPerformanceStats(data) {
    
    let statsData = {
      currAdViews: 0,
      lastAdViews: 0,
      prevAdViews: 0.,
      currAdClicks: 0,
      lastAdClicks: 0,
      prevAdClicks: 0,
      currFeedbacks: 0,
      lastFeedbacks: 0,
      prevFeedbacks: 0,
      currPublished: 0,
      lastPublished: 0,
      prevPublished: 0,
    }
    
    log.debug("loadBroadcastPerformanceStats: data=", data)    

    if (data) {
      statsData.currAdViews = data.adViewTotal30Days
      statsData.currAdClicks = data.adClickTotal30Days
      statsData.currFeedbacks = data.feedbackTotal30Days
      statsData.lastAdViews = data.adViewTotal60Days
      statsData.lastAdClicks = data.adClickTotal60Days
      statsData.lastFeedbacks = data.feedbackTotal60Days
      statsData.prevAdViews = data.adViewTotal90Days
      statsData.prevAdClicks = data.adClickTotal90Days
      statsData.prevFeedbacks = data.feedbackTotal90Days
      statsData.currPublished = data.publishedTotal30Days
      statsData.lastPublished = data.publishedTotal60Days
      statsData.prevPublished = data.publishedTotal90Days
    }

    log.debug("loadBroadcastPerformanceStats: statsData", statsData)    

    setBroadcastPerformanceStats(statsData)
  
    setAdViewsChartData({
      labels: ["90 Days", "60 Days", "30 Days"],
      datasets: [{
        label: intl.msg("views"),
        data: [intl.int(statsData.prevAdViews), intl.int(statsData.lastAdViews), intl.int(statsData.currAdViews)],
        borderColor: "rgb(87, 151, 210)",  /*"rgba(255, 99, 132, 0.5)",*/
        backgroundColor: "rgb(87, 151, 210)",  /*"rgba(255, 99, 132, 0.5)",*/
        borderWidth: 1,
      }]
    })

    setAdClicksChartData({
      labels: ["90 Days", "60 Days", "30 Days"],
      datasets: [{
        label: intl.msg("clicks"),
        data: [intl.int(statsData.prevAdClicks), intl.int(statsData.lastAdClicks), intl.int(statsData.currAdClicks)],
        borderColor: "rgb(87, 151, 210)",
        backgroundColor: "rgb(87, 151, 210)", 
        borderWidth: 1,
      }]
    })

    setFeedbacksChartData({
      labels: ["90 Days", "60 Days", "30 Days"],
      datasets: [{
        label: intl.msg("responses"),
        data: [intl.int(statsData.prevFeedbacks), intl.int(statsData.lastFeedbacks), intl.int(statsData.currFeedbacks)],
        borderColor: "rgb(87, 151, 210)",
        backgroundColor: "rgb(87, 151, 210)", 
        borderWidth: 1,
      }]
    })

    setPublishedChartData({
      labels: ["90 Days", "60 Days", "30 Days"],
      datasets: [{
        label: intl.msg("published"),
        data: [intl.int(statsData.prevPublished), intl.int(statsData.lastPublished), intl.int(statsData.currPublished)],
        borderColor: "rgb(87, 151, 210)",
        backgroundColor: "rgb(87, 151, 210)",
        borderWidth: 1,
      }]
    })

  }

  function Spacer() {
    return (
      <div className="mr-2"></div>
    )
  }

  // function CreditStats(props) {
  //   return (
  //    tenant && tenant.credits && tenant.credits.amount &&
  //       <Widget title={intl.msg("credits")} text={<CreditsExpiringText credits={tenant.credits}/>} onClick={() => window.location = "/broadcasts"} size={props.size}>
  //         <WidgetMetric>
  //           {intl.num(tenant.credits.amount)}
  //           <small className="pl-1">{tenant.region?.currencyCode || ""}</small>
  //         </WidgetMetric>
  //       </Widget>
  //   )
  // }

  function BroadcastViewsStats(props) {
    return (
      broadcastPerformanceStats &&
        <Widget title={intl.msg("views")} subtitle={intl.msg("90_day_trend")} onClick={() => window.location = mkHref({suffix: "/broadcasts", tenant: props.tenant})} size={props.size}>
          <WidgetMiniLineChart data={adViewsChartData}/>
          <WidgetMetric title={intl.int(broadcastPerformanceStats.currAdViews)}>
            {intl.int(broadcastPerformanceStats.currAdViews, "compact")}
          </WidgetMetric>
        </Widget>
    )
  }

  function BroadcastClicksStats() {
    return (
      broadcastPerformanceStats &&
        <Widget title={intl.msg("clicks")} subtitle={intl.msg("90_day_trend")} onClick={() => window.location = mkHref({suffix: "/broadcasts", tenant: props.tenant})} size={props.size}>
          <WidgetMiniLineChart data={adClicksChartData}/>
          <WidgetMetric title={intl.int(broadcastPerformanceStats.currAdClicks)}>
            {intl.int(broadcastPerformanceStats.currAdClicks, "compact")}
          </WidgetMetric>
        </Widget>
    )
  }

  function BroadcastFeedbacksStats() {
    return (
      broadcastPerformanceStats &&
        <Widget title={intl.msg("responses")} subtitle={intl.msg("90_day_trend")} onClick={() => window.location = mkHref({suffix: "/responses", tenant: props.tenant})} size={props.size}>
          <WidgetMiniLineChart data={feedbacksChartData}/>
          <WidgetMetric title={intl.int(broadcastPerformanceStats.currFeedbacks)}>
            {intl.int(broadcastPerformanceStats.currFeedbacks, "compact")}
          </WidgetMetric>
        </Widget>                    
    )
  }

  function BroadcastPublishedStats(props) {
    return (
      broadcastPerformanceStats &&
        <Widget title={intl.msg("published")} subtitle={intl.msg("90_day_trend")} onClick={() => window.location = mkHref({suffix: "/broadcasts", tenant: props.tenant})} size={props.size}>
          <WidgetMiniLineChart data={publishedChartData}/>
          <WidgetMetric title={intl.int(broadcastPerformanceStats.currPublished)}>
            {intl.int(broadcastPerformanceStats.currPublished, "compact")}
          </WidgetMetric>
        </Widget>
    )
  }

  function IncomeStats() {
    return (
      <></>
      // incomeStats &&
      //   <>
      //     <Widget title={intl.msg("income")}  subtitle={intl.msg("90_day_trend")} onClick={() => window.location = mkHref({suffix: "/revenue", tenant: props.tenant})}>
      //       <WidgetMiniLineChart data={incomeChartData}/>
      //       <WidgetMetric>
      //         {intl.num(props.tenant.accessingAs("*/*/SYSTEM") ? incomeStats.curr : incomeStats.curr , {minimumFractionDigits: 2, maximumFractionDigits: 2})}
      //         <small className="pl-1">{props.tenant.accessingAs("*/*/SYSTEM") ? "USD" : props.tenant.region?.currencyCode}</small>
      //       </WidgetMetric>
      //     </Widget>
      //   </>
    )
  }

  function ClientQuickStats(props) {
    return (
      <>
        {
          (session.user.isSystem() || session.user.isReseller()) &&
            <IncomeStats/>
        }
        <CreditsWidget tenant={props.tenant}/>
        {
          props.tenant.accessingAs("*/MEMBER/*") ? (
            <>
              <BroadcastPublishedStats/>
              <BroadcastViewsStats/>
              <BroadcastClicksStats/>
              {/*<BroadcastFeedbacksStats/>*/}
            </>
          ) : (
            <BroadcastPublishedStats/>
          )
        }
      </>
    )    
  }

  function ResellerQuickStats(props) {
    return (
      <>
        <IncomeStats/>
        {/*<CreditsWidget tenant={props.tenant}/>*/}
        {/*<BroadcastViewsStats/>*/}
        {/*<BroadcastClicksStats/>*/}
        {/*<BroadcastFeedbacksStats/>*/}
      </>
    )    
  }

  function SolvQuickStats(props) {
    return (
      <>
        <IncomeStats/>
        <CreditsWidget tenant={props.tenant}/>
        <BroadcastViewsStats/>
        <BroadcastClicksStats/>
        {/*<BroadcastFeedbacksStats/>*/}
      </>
    )
  }

  return (
    props.tenant?.isClient() ? (
      ClientQuickStats(props)
    ) : props.tenant?.isReseller() ? (
      ResellerQuickStats(props)
    ) : props.tenant?.isSystem() ? (
      SolvQuickStats(props)
    ) : (
        <></>
    )
  )

}

export function BroadcastSummaryWidgets(props) {

  const { api, auth: { session: { user, tenant } } } = useSolv()
  const { intl } = useIntlEx()

  const [broadcastPerformanceStats, setBroadcastPerformanceStats] = useState(null)
  const [adViewsChartData, setAdViewsChartData] = useState(null)
  const [adClicksChartData, setAdClicksChartData] = useState(null)
  const [feedbacksChartData, setFeedbacksChartData] = useState(null)
  
  useEffect(() => {
    log.debug(">>>Z: broadcastPerformanceStats=", props.broadcastPerformanceStats?.stats)
    if (props.broadcastPerformanceStats) {
      if (props.broadcastPerformanceStats.stats) {
        setBroadcastPerformanceStats(props.broadcastPerformanceStats.stats)
      }
      else {
        setBroadcastPerformanceStats(props.broadcastPerformanceStats)
      }
    }
    else {
      // loadBroadcastStats(props.broadcastId)
    }
  },[props])

  function loadBroadcastStats(broadcastId) { 

    log.debug("loadBroadcastStats: props=", props)

    let url = `/v1/stats/broadcasts/${broadcastId}/summarized/`

    log.debug("loadBroadcastStats: url=", url)

    return api.get(url)
      .then(({data}) => {
         log.debug("loadSummarizedStats: data=", data)
         setBroadcastPerformanceStats(data)
      })
      .catch((error) => {
        log.debug("Error", error)
      })
  }

  function BroadcastViewStats() {
    return (
      broadcastPerformanceStats &&
        <Widget title={intl.msg("views")} className={styles.centered} iconClass={props.showIcons ? "far fa-eye" : ""} size={props.size}>
          <WidgetMetric title={intl.int(broadcastPerformanceStats.adViews)}>
            {intl.int(broadcastPerformanceStats.adViews, "compact")}
          </WidgetMetric>  
          <WidgetMiniLineChart data={adViewsChartData}/>
        </Widget>
    )
  }

  function BroadcastClickStats() {
    return (
      broadcastPerformanceStats &&
        <Widget title={intl.msg("clicks")} className={styles.centered} iconClass={props.showIcons ? "fas fa-hand-point-up" : ""} size={props.size}>
          <WidgetMetric title={intl.int(broadcastPerformanceStats.adClicks)}>
            {intl.int(broadcastPerformanceStats.adClicks, "compact")}
          </WidgetMetric>  
          <WidgetMiniLineChart data={adClicksChartData}/>
        </Widget>
    )
  }

  function BroadcastFeedbackStats() {

    function handleResponsesClick() {
      if (broadcastPerformanceStats.feedbacks && broadcastPerformanceStats.feedbacks > 0) {
        if (props.onResponsesClick) {
          props.onResponsesClick()
        }
        else {
          window.location = `/responses?broadcasts=${broadcastPerformanceStats.broadcastName}`
        }
      }
    }

    return (
      broadcastPerformanceStats &&
        <Widget title={intl.msg("responses")} className={styles.centered} iconClass={props.showIcons ? "far fa-comment-dots" : ""} onClick={handleResponsesClick} size={props.size}>
          {/*<Widget title={intl.msg("widget_responses")} className={styles.centered} iconClass={props.showIcons ? "far fa-comment-dots" : ""} onClick={broadcastPerformanceStats.feedbacks && broadcastPerformanceStats.feedbacks > 0 ? () => window.location = `/responses?broadcasts=${broadcastPerformanceStats.broadcastName}` : null} size={props.size}>*/}
          <WidgetMetric title={intl.int(broadcastPerformanceStats.feedbacks)}>
            {intl.int(broadcastPerformanceStats.feedbacks, "compact")}
          </WidgetMetric>  
          <WidgetMiniLineChart data={feedbacksChartData}/>
        </Widget>                    
    )
  }

  return (
    <div className={styles["widget-group"]}>
      <BroadcastViewStats/>
      <BroadcastClickStats/>
    </div>
  )

}

