import React, {useState, useReducer, useEffect, useRef} from 'react'
import { useParams, useHistory, useLocation }  from 'react-router-dom'
import {AsyncPaginate, reduceGroupedOptions} from "react-select-async-paginate";
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import useStateRef from 'react-usestateref'
import {useSolv} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {Loading, MainContainer} from "../components/FormComps"
import {PageDialogContainer, PageDialog, PageDialogMainPanel} from "../components/PageDialog"
import {processImageFile, ImageDropBox, uploadImage} from "../components/ImageUtils.js"
import {simpleReducer} from "../components/ReactUtils"
import {BroadcastSummaryWidgets} from "../components/Widget"
import {isBlankString} from "../components/StringUtils";
import {PageDialogHeader, PageDialogFooter} from "./PageDialogUtils"
import {openDialog} from "../components/DialogUtils";
import getLogger from "../components/Logging"
import ImageDialog from "../components/ImageDialog"
import {clearError, InfoBlock, InfoText} from "../components/ValidationUtils"
import ResponsesDialog from "./ResponsesDialog";
import '../App.css'
import './PetSolv.css'

const log = getLogger("Home")

export default function Home() {

  const {api, auth: {session: {user}}, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const location = useLocation()
  const history = useHistory()

  const params = useParams()

  const [tenant, setTenant] = useState(null)
  const [region, setRegion] = useState(null)

  const [startsOn, setStartsOn] = useState(null)
  const [startDays, setStartDays] = useState(null)
  const [expiressOn, setExpiresOn] = useState(null)
  const [expireDays, setExpireDays] = useState(null)
  const [broadcast, updateBroadcast] = useReducer(simpleReducer, {})
  const [creativeImageUrl, setCreativeImageUrl] = useState(null)
  const [creativeImageInfo, setCreativeImageInfo] = useState(null)
  const creativeImagePickerDialogRef = useRef(null)
  const [pageImageUrl, setPageImageUrl] = useState(null)
  const [pageImageInfo, setPageImageInfo] = useState(null)
  const pageImagePickerDialogRef = useRef(null)
  const [targetLocations, setTargetLocations, targetLocationsRef] = useStateRef(null)
  const [step, setStep] = useState(null)
  const [broadcastEditStep, setBroadcastEditStep] = useState("MESSAGE")

  const [touched, setTouched] = useState(null)
  const [alert, setAlert] = useState(null)

  useEffect(() => {

    log.debug("useEffect: user=", user)
    if (!user.onboardedOn || user.status === "ONBOARDING") {
      window.location = "/onboarding"
    }

    // setFatal("DENIED_ACCESS")

    setBusy(intl.msg("loading"))
    api.getTenantInfo(user.tenantId)
      .then(({data}) => {
        log.debug("Tenant loaded: ", data)
        const tenant = data.tenant
        setTenant(tenant)
        setStartsOn(tenant.credits.startsOn)
        setExpiresOn(tenant.credits.expiresOn)

        let now = dayjs()
        let startTime = dayjs(tenant.credits.startsOn)
        let expiryTime = dayjs(tenant.credits.expiresOn)
        log.debug(">>>>now, startTime, expiryTime", now, startTime, expiryTime, startTime.diff(now, 'days', true))
        let sdays = tenant.credits?.startsOn ? parseInt(Math.ceil(startTime.diff(now, 'days', true))): 0
        let edays = tenant.credits?.expiresOn ? parseInt(Math.ceil(expiryTime.diff(now, 'days', true))) : 365

        log.debug("sdays, edays=", sdays, edays)

        setStartDays(sdays)
        setExpireDays(edays)

        if (edays <= 0) {
          setStep("EXPIRED")
        }
        else if (sdays > 0) {
          setStep("PENDING")
        }
        else {
          setStep("BROADCAST")
        }

        log.debug("loading broadcast...")
        api.getFirstBroadcast(user.tenantId)
          .then(({data}) => {
            log.debug("Broadcast loaded: data=", data)
            updateBroadcast(data)
            setCreativeImageUrl(data.creativeImageUrl)
            setPageImageUrl(data.pageImageUrl)
            const tl = data.targetLocations.filter(l => l.locationId !== "00000000000000000000000000000000").map( t => {
              return {label: t.displayName, value: t.locationId, pop: t.pop}
            })
            log.debug("Loaded targetLocations: tl=", tl)
            setTargetLocations(tl)
          })
          .catch((error) => {
            log.debug("Load broadcast failed", error)
            setFatal({type:"OOPS", error: error})
          })
      })
      .catch((error) => {
        log.debug("Error", error)
        setFatal({type:"OOPS", error: error})
      })
      .finally(() => {
        setBusy(null)
      })

  }, [])

  // useEffect(() => {
  //   if ("BROADCAST" === step) {
  //     log.debug("Step = BROADCAST, loading broadcast...")
  //     api.getFirstBroadcast()
  //       .then(({data}) => {
  //         log.debug("Broadcast loaded: data=", data)
  //         updateBroadcast(data)
  //         setCreativeImageUrl(data.creativeImageUrl)
  //         setPageImageUrl(data.pageImageUrl)
  //         const tl = data.targetLocations.filter(l => l.locationId !== "00000000000000000000000000000000").map( t => {
  //           return {label: t.displayName, value: t.locationId, pop: t.pop}
  //         })
  //         log.debug("Loaded targetLocations: tl=", tl)
  //         setTargetLocations(tl)
  //         setStep("BROADCAST")
  //       })
  //       .catch((error) => {
  //         log.debug("Load broadcast failed", error)
  //         setFatal({type:"OOPS", error: error})
  //       })
  //   }
  // }, [step])

  function getCurrentLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
          (result) => {
            resolve(result)
          },
          (error) => {
            reject(error)
          });
    })
  }

  function makeLocationOptions(res) {
    log.debug("makeLocationOptions: res=", res)
    if (res.data) {
      let opt = res.data.map(c => {
        return {
          label: c.ttdType,
          options: [
            {
              value: c.locationId,
              label: c.displayName,
              pop: c.pop
            }
          ],
        }

      })
      let rs = {
        options: opt,
      }
      if (res.nextCursor) {
        rs = {
          ...rs,
          hasMore: true,
          additional: {
            cursor: res.nextCursor
          }
        }
      }
      log.debug("makeLocationOptions: res=", rs)
      return rs
    }
    else {
      return null
    }
  }

  async function loadCurrentLocations(regionId) {
    setBusy(intl.msg("detecting_location"))
    try {
      if ("geolocation" in navigator) {
        let loc
        try {
          loc = await getCurrentLocation()
          log.debug("loadCurrentLocations: currentLocation=", loc)
        } catch (error) {
          log.debug("loadCurrentLocations: Error loading locations by latlong: error=", error.code)
          let msg
          switch (error.code) {
            case 1:
              msg = "warn_location_services_denied"
              break
            case 2:
              msg = "warn_location_services_unavailable"
              break
            default:
              msg = "warn_location_services_failed"
              break
          }
          setAlert({warning: intl.msg(msg)})
        }
        if (loc) {
          const coords = loc.coords
          let url = `/v1/regions/${regionId}/locations/_coords?lat=${coords.latitude}&lng=${coords.longitude}&limit=1`
          let data = await api.get(url)
          if (data && data.data.length && data.data.length > 0) {
            const opts = data.data.map((l) => {
              return ({
                value: l.locationId,
                label: l.displayName,
                pop: l.pop
              })
            })
            log.debug("loadCurrentLocations: opts=", opts)
            if (!targetLocationsRef.current || targetLocationsRef.current.length === 0) {
              setTargetLocations(opts)
            }
          }
        }
      }
    }
    finally {
      setBusy(null)
    }

  }

  async function loadTargetLocations(search, loadedOptions, additional) { //, loadedOptions, { cursor }) {

    log.debug("loadTargetLocations: search=, loadedOptions, additional=", search, loadedOptions, additional, tenant)

    let url = `/v1/regions/${tenant.regionId}/locations/_query?search=${search ? search.trim() : ""}&limit=20`
    if (additional && additional.cursor) {
      url = url + `&cursor=${additional.cursor}`
    }
    log.debug("loadTargetLocations: url=", url)

    try {
      let res = await api.get(url)
      if (res) {
        return makeLocationOptions(res)
      }
    }
    catch (error) {
      log.debug("Error loading locattion: error", JSON.stringify(error))
    }
  }

  async function saveBroadcastMessage() {

    setBusy(intl.msg("saving"))
    try {

      try {

        let finalCreativeImageUrl = broadcast.creativeImageUrl
        if (broadcast.creativeImageUrl && broadcast.creativeImageUrl.startsWith("data:")) {
          finalCreativeImageUrl = await uploadImage({
            api: api,
            imageData: broadcast.creativeImageUrl,
            imagePath: `t/${user.tenantId}/b/${broadcast.broadcastId}/m/0`,
          })
        }

        let finalPageImageUrl = broadcast.pageImageUrl
        if (broadcast.pageImageUrl && broadcast.pageImageUrl.startsWith("data:")) {
          finalPageImageUrl = await uploadImage({
            api: api,
            imageData: broadcast.pageImageUrl,
            imagePath: `t/${user.tenantId}/b/${broadcast.broadcastId}/p/0`,
          })
        }

        broadcast.pageForm = {...broadcast.creativeForm}

        const payload = {
          ...broadcast,
          creativeImageUrl: finalCreativeImageUrl,
          pageImageUrl: finalPageImageUrl,
          targetLocations: targetLocations.length > 0 ? targetLocations.map(l => l.value) : ["00000000000000000000000000000000"],
        }

        log.debug("saveBroadcastMessage: payload=", payload)

        const data = await api.updateBroadcast(broadcast.broadcastId, payload)

        log.debug("saveBroadcastMessage: data=", data)

      }
      catch (error) {
        handleError(error)
        return false
      }
    }
    finally {
      setBusy(null)
    }
    return true
  }

  function handleTargetLocationsChange(value) {
    log.debug("handleTargetLocationsChange: value=", value)
    setTargetLocations(value)
    // setTouched(true)
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", JSON.stringify(error))
      const code = error.code
      setAlert({error: intl.msg("error_failed")})
    } else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function isEditable() {
    return true
  }

  async function handleBroadcastMessageTabNextClick() {

    if (isBlankString(broadcast.creativeHeader)) {
      setAlert({error: intl.msg("error_broadcast_creative_header_required")})
      return false
    }

    if (isBlankString(broadcast.creativeTitle)) {
      setAlert({error: intl.msg("error_broadcast_creative_title_required")})
      return false
    }

    if (isBlankString(broadcast.creativeImageUrl)) {
      setAlert({error: intl.msg("error_broadcast_creative_image_required")})
      return false
    }

    for (let field of broadcast.creativeForm?.fields) {
      if (isBlankString(field.value)) {
        log.debug("handleBroadcastMessageTabNextClick: field=", field)
        setAlert({error: intl.msg(`error_broadcast_${field.label.trim().toLowerCase().replace(" ", "_")}_required`)})
        return false
      }
    }

    if (await saveBroadcastMessage()) {
      if (!targetLocationsRef.current || targetLocationsRef.current.length === 0) {
        await loadCurrentLocations(tenant.regionId)
      }
      setBroadcastEditStep("LOCATION")
    }

    setAlert(null)

  }

  async function handleBroadcastLocationsTabPrevClick() {
    setBroadcastEditStep("MESSAGE")
  }

  async function handleBroadcastLocationsTabNextClick() {

    if (!targetLocations || targetLocations.length == 0) {
      setAlert({error: intl.msg("error_broadcast_locations_required")})
      return false
    }

    if (await saveBroadcastMessage()) {
      setBroadcastEditStep("PAGE")
    }
  }

  async function handleBroadcastPageTabPrevClick() {
    setBroadcastEditStep("LOCATION")
  }

  async function handleBroadcastPageTabNextClick() {

    if (isBlankString(broadcast.pageImageUrl)) {
      setAlert({error: intl.msg("error_broadcast_page_image_required")})
      return false
    }

    if (await saveBroadcastMessage()) {
      setBroadcastEditStep("CONFIRM")
    }

    setAlert(null)
  }

  async function handleBroadcastConfirmTabPrevClick() {
    setBroadcastEditStep("PAGE")
  }

  async function handleBroadcastConfirmTabPublishClick() {

    setBusy(intl.msg("publishing"))
    try {

      const payload = {
        provider: "SOLV_CREDITS",
        broadcastId: broadcast.broadcastId,
        details: {}
      }

      log.debug("handlePublishClick: payload=", payload)

      const data = await api.publishBroadcast(payload)

      log.debug("handlePublishClick: data=", data)

      window.location = "/"

    }
    catch (error) {
      handleError(error)
    }
    finally {
      setBusy(null)
    }
  }

  async function handleCreativeHeaderChange(e) {
    updateBroadcast({creativeHeader: e.target.value})
    setTouched(true)
  }

  async function handleCreativeTitleChange(e) {
    log.debug("handleCreativeTitleChange: v=", e.target.value)
    updateBroadcast({creativeTitle: e.target.value})
    setTouched(true)
  }

  async function handleCreativeContentChange(e) {
    updateBroadcast({creativeContent: e.target.value})
    setTouched(true)
  }

  async function handleCreativePhotoChange(e) {

    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))
      try {
        result = await processImageFile({
          file: file,
          maxFileSize: 100_000,
          width: 140,
          height: 180,
        })
      }
      catch (ex) {
        setAlert({error: intl.msg("error_image_process_failed")})
      }
      finally {
        setBusy(null)
      }

      if (result.error) {
        setCreativeImageInfo(result)
        const el = document.getElementById("inp_dlg_creativeImagePicker")
        el.click()
      }
      else {
        updateBroadcast({creativeImageUrl: result.dataUrl})
      }

      clearError("inp_logoUrl")
    }
  }

  function handleCreativePhotoClear() {
    updateBroadcast({creativeImageUrl: null})
  }

  async function handleCreativeFooterChange(e) {
    updateBroadcast({creativeFooter: e.target.value})
  }

  function handleCreativeImageUrlChange(v) {
    updateBroadcast({creativeImageUrl: v})
  }

  function handleCreativeFieldChange(f, e) {
    const v = e.target.value
    log.debug("handleCreativeFieldChange: f, v=", f, v)
    let field = broadcast.creativeForm?.fields?.find((x) => x.id === f.id)
    log.debug("handleCreativeFieldChange: creativeForm.fields=", broadcast.creativeForm?.fields)
    field.value = v
    updateBroadcast(broadcast)
  }

  // async function handlePageHeaderChange(e) {
  //   updateBroadcast({pageHeader: e.target.value})
  //   setTouched(true)
  // }
  //
  // async function handlePageTitleChange(e) {
  //   log.debug("handlePageTitleChange: v=", e.target.value)
  //   updateBroadcast({pageTitle: e.target.value})
  //   setTouched(true)
  // }

  async function handlePagePhotoChange(e) {

    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))
      try {
        result = await processImageFile({
          file: file,
          maxFileSize: 2_000_000,
          width: 460,
          height: 589,
        })
      }
      catch (ex) {
        setAlert({error: intl.msg("error_image_process_failed")})
      }
      finally {
        setBusy(null)
      }

      if (result.error) {
        setPageImageInfo(result)
        const el = document.getElementById("inp_dlg_pageImagePicker")
        el.click()
      }
      else {
        updateBroadcast({pageImageUrl: result.dataUrl})
      }

      clearError("inp_logoUrl")
    }
  }

  function handlePageImageUrlChange(v) {
    updateBroadcast({pageImageUrl: v})
  }

  function handlePagePhotoClear() {
    updateBroadcast({pageImageUrl: creativeImageUrl})
  }

  function handlePageContentChange(e) {
    const v = e.target.value
    updateBroadcast({pageContent: v})
  }

  function mkFieldClass(field) {
    log.debug("mkFieldClass: field=", field)
    let className = ""
    if (field.opts) {
      if (field.opts.layout) {
        className += ` field-style-${field.opts.layout.toLowerCase()}`
      }
      if (field.opts.labelStyle) {
        className += ` field-style-label-${field.opts.labelStyle.toLowerCase()}`
      }
      if (field.opts.valueStyle) {
        className += ` field-style-value-${field.opts.valueStyle.toLowerCase()}`
      }
    }
    return className
  }

  function mkFieldPlaceholder(field) {
    return (`petsolv_broadcast_pet_${field.label.trim().toLowerCase().replace(" ", "_")}_placeholder`)
  }

  function mkTargetLocations() {
    let l = targetLocations.map(l => l.label).join(", ")
    return l
  }

  return (
      <>
        <MainContainer>
          {
            "EXPIRED" === step ? (
                <PageDialogContainer>
                  <PageDialog header={PageDialogHeader} footer={PageDialogFooter} size="sm" align="center">
                    <PageDialogMainPanel title={""} alert={alert}>
                      <i className="fas fa-hourglass"></i>
                    </PageDialogMainPanel>
                  </PageDialog>
                </PageDialogContainer>
            ) : "PENDING" === step ? (
                <>
                  <PageDialogContainer size="sm">
                    <PageDialog header={PageDialogHeader} footer={PageDialogFooter} size="sm" align="center">
                      <PageDialogMainPanel title={""} alert={alert}>
                        <div style={{border: "1px solid var(--form-divider-color", backgroundColor: "#fff", padding: "4px"}}>
                          {/*<span style={{fontSize: "50pt"}}><i className="fas fa-hourglass-start"></i></span>*/}
                          {
                            broadcast && broadcast.creativeImageUrl ? (
                              <img src={broadcast.creativeImageUrl} style={{width: "auto", height: "174px"}}/>
                            ) : (
                              <img src="https://sassets.petsolv.com/brand/petsolv-hero-2.png" style={{width: "150px", height: "174px"}}/>
                            )
                          }
                        </div>
                        {/*<h2>*/}
                        {/*  {intl.int(startDays)}<span style={{fontWeight: "normal"}}> days remaining</span>*/}
                        {/*</h2>*/}
                        <p className="mt-3">
                          {intl.msg("petsolv_protection_plan_pending", {petName: broadcast.creativeTitle, startsOn: intl.date(dayjs(startsOn)), daysRemaining: intl.fmDaysFrom(startsOn)})}
                          {/*Your account is not currently active and will become live on <b>{intl.date(dayjs(startsOn))}</b>, at which point you will be able to launch a broadcast.*/}
                        </p>
                      </PageDialogMainPanel>
                    </PageDialog>
                  </PageDialogContainer>

                </>
            ) : "BROADCAST" === step && broadcast ? (
                <>
                  <PageDialogContainer>
                    <PageDialog header={PageDialogHeader} footer={PageDialogFooter} size="md" align="center">

                      {
                        ("DRAFT" === broadcast?.status) ? (
                            <>
                              <PageDialogMainPanel title={intl.msg("petsolv_broadcast_edit_title")} alert={alert}>
                                <div className="d-flex align-items-center justify-content-center flex-column mt-3">
                                  {
                                    "MESSAGE" === broadcastEditStep ? (
                                        <div>
                                          <div>
                                            {intl.msg("petsolv_broadcast_edit_message_tab_text")}
                                          </div>
                                          <div className="d-flex justify-content-center mt-3 petsolv-skin">
                                            <div className="adcreative-container">
                                              <div className="header">
                                                <center>
                                                  <input id="inp_broadcastHeader" type="text" className="input" title={intl.msg("petsolv_onboarding_broadcast_header_tooltip")} value={broadcast.creativeHeader} onChange={handleCreativeHeaderChange} disabled={!isEditable()}/>
                                                </center>
                                              </div>
                                              <div className="photo">
                                                <ImageDropBox
                                                    id="inp_petPhoto"
                                                    image={broadcast.creativeImageUrl}
                                                    imageClassName={"primary-photo-custom"}
                                                    textClass={"color-white"}
                                                    iconClass={"fas fa-paw"}
                                                    width={140}
                                                    height={180}
                                                    backdrop={false}
                                                    absolute={true}
                                                    onChange={handleCreativePhotoChange}
                                                    onClear={handleCreativePhotoClear}
                                                    disabled={!isEditable()}/>
                                              </div>
                                              <div className="title">
                                                <input id="inp_broadcastTitle" type="text" placeholder={intl.msg(`petsolv_broadcast_pet_name_placeholder`)} title={intl.msg("petsolv_onboarding_broadcast_title_tooltip")} value={broadcast.creativeTitle} onChange={handleCreativeTitleChange} disabled={!isEditable()}/>
                                              </div>
                                              <div className="content">
                                                <div className="fields">
                                                  {
                                                    broadcast.creativeForm?.fields?.map((field) => {
                                                      return (
                                                          <div className={`field-row ${mkFieldClass(field)}`}>
                                                            <div className="field-label">{field.label}:</div>
                                                            <div className="field-value">
                                                              {
                                                                "TEXT_LINE" === field.type ? (
                                                                    <input type="text" value={field.value} placeholder={intl.msg(mkFieldPlaceholder(field))} onChange={(e) => handleCreativeFieldChange(field, e)}/>
                                                                ) : "TEXT_BLOCK" === field.type ? (
                                                                    <textarea rows="4" value={field.value} placeholder={intl.msg(mkFieldPlaceholder(field))} onChange={(e) => handleCreativeFieldChange(field, e)}/>
                                                                ) : (
                                                                    <>{field.value}</>
                                                                )
                                                              }
                                                            </div>
                                                          </div>
                                                      )
                                                    })
                                                  }
                                                </div>
                                              </div>
                                              <div className="footer">
                                                <center>
                                                  <input id="inp_broadcastFooter" type="text"  className="input" title={intl.msg("petsolv_onboarding_broadcast_footer_tooltip")} value={broadcast.creativeFooter} onChange={handleCreativeFooterChange} disabled={!isEditable()}/>
                                                </center>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="mt-4">
                                            <button className="btn btn-primary" type="button" onClick={handleBroadcastMessageTabNextClick}><i className="fas fa-caret-right"></i>{intl.msg("next")}</button>
                                          </div>
                                        </div>
                                    ) : "LOCATION" === broadcastEditStep ? (
                                        <div>
                                          <div>
                                            {intl.msg("petsolv_broadcast_edit_locations_tab_text")}
                                          </div>
                                          <AsyncPaginate
                                              id="inp_targetLocations"
                                              className="react-select mt-3"
                                              classNamePrefix="react-select"
                                              cacheOptions
                                              isMulti
                                              isClearable
                                              value={targetLocations}
                                              debounceTimeout={800}
                                              defaultOptions={true}
                                              loadOptions={loadTargetLocations}
                                              reduceOptions={reduceGroupedOptions}
                                              onChange={handleTargetLocationsChange}
                                              isLoading={true}
                                              isDisabled={!isEditable()}
                                          />
                                          <div className="mt-4">
                                            <button className="btn btn-secondary mr-2" type="button" onClick={handleBroadcastLocationsTabPrevClick}><i className="fas fa-caret-left"></i>{intl.msg("previous")}</button>
                                            <button className="btn btn-primary" type="button" onClick={handleBroadcastLocationsTabNextClick}><i className="fas fa-caret-right"></i>{intl.msg("next")}</button>
                                          </div>
                                        </div>
                                    ) : "PAGE" === broadcastEditStep ? (
                                        <div>

                                          <div>
                                            {intl.msg("petsolv_broadcast_edit_page_tab_text")}
                                          </div>

                                          <div className="form-card d-flex justify-content-center mt-4">

                                            <div className="form-group" style={{backgroundColor: "rgba(var(--form-background-color-rgb), 0.6)", borderRadius: "5px", border: "1px solid var(--form-border-color)", padding: "0 20px 20px 20px"}}>
                                              <div className="photo mt-4 mb-4">
                                                <ImageDropBox
                                                    id="inp_pageImageUrl"
                                                    image={broadcast.pageImageUrl}
                                                    imageClassName={"secondary-photo"}
                                                    textClass={"color-white"}
                                                    iconClass={"fas fa-paw"}
                                                    width={300}
                                                    height={498}
                                                    backdrop={false}
                                                    absolute={true}
                                                    onChange={handlePagePhotoChange}
                                                    onClear={handlePagePhotoClear}
                                                    disabled={!isEditable()}/>
                                                <InfoBlock>
                                                  <InfoText>{intl.msg("petsolv_broadcast_page_photo_helptext_1")}</InfoText>
                                                  <InfoText>{intl.msg("petsolv_broadcast_page_photo_helptext_2")}</InfoText>
                                                  <InfoText>{intl.msg("helptext_required")}</InfoText>
                                                </InfoBlock>
                                              </div>

                                              <div className="mt-5">
                                                <textarea id="inp_pageContent" className="form-control input" placeholder={intl.msg("petsolv_broadcast_page_details_placeholder")} value={broadcast.pageContent} rows="5" onChange={handlePageContentChange} disabled={!isEditable()}/>
                                                <InfoBlock>
                                                  <InfoText>{intl.msg("petsolv_broadcast_page_details_helptext_1")}</InfoText>
                                                  <InfoText>{intl.msg("helptext_optional")}</InfoText>
                                                </InfoBlock>
                                              </div>

                                            </div>
                                          </div>

                                          <div className="mt-4">
                                            <button className="btn btn-secondary mr-2" type="button" onClick={handleBroadcastPageTabPrevClick}><i className="fas fa-caret-left"></i>{intl.msg("previous")}</button>
                                            <button className="btn btn-primary" type="button" onClick={handleBroadcastPageTabNextClick}><i className="fas fa-caret-right"></i>{intl.msg("next")}</button>
                                            {/*<button className="btn btn-primary" type="button" onClick={handlePublishClick}><i className="fas fa-paper-plane"></i>{intl.msg("broadcast_publish")}</button>*/}
                                          </div>

                                        </div>
                                    ) : "CONFIRM" === broadcastEditStep ? (
                                        <div>

                                          <div>
                                            {intl.msg("petsolv_broadcast_edit_confirm_tab_text")}
                                          </div>

                                          <div className="mt-4" style={{backgroundColor: "rgba(var(--form-background-color-rgb), 0.6)", borderRadius: "5px", border: "1px solid var(--form-border-color)", padding: "0px 20px 20px 20px"}}>
                                            <div className="d-flex justify-content-center mt-3">
                                              <label>Broadcast Message:</label>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                              <div className="adcreative-container">
                                                <div className="header">
                                                  {broadcast.creativeHeader}
                                                </div>
                                                <div className="photo">
                                                  <img src={broadcast.creativeImageUrl}/>
                                                </div>
                                                <div className="title">
                                                  '{broadcast.creativeTitle}'
                                                </div>
                                                <div className="content">
                                                  <div className="fields">
                                                    {
                                                      broadcast.creativeForm?.fields?.map((field) => {
                                                        return (
                                                            <div className={`field-row ${mkFieldClass(field)}`}>
                                                              <div className="field-label">
                                                                {field.label}:
                                                              </div>
                                                              <div className="field-value">
                                                                {field.value}
                                                              </div>
                                                            </div>
                                                        )
                                                      })
                                                    }
                                                  </div>
                                                </div>
                                                <div className="footer">
                                                  {broadcast.creativeFooter}
                                                </div>
                                              </div>
                                            </div>

                                            <div className="mt-4">
                                              <div>
                                                <label>Broadcast Locations:</label>
                                              </div>
                                              <div style={{backgroundColor: "var(--form-control-background-color)", border: "1px solid var(--form-border-color)", borderRadius: "5px"}}>
                                                <label className="text" style={{margin: "0.5rem"}}>{mkTargetLocations()}</label>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mt-4">
                                            <button className="btn btn-secondary mr-2" type="button" onClick={handleBroadcastConfirmTabPrevClick}><i className="fas fa-caret-left"></i>{intl.msg("previous")}</button>
                                            <button className="btn btn-primary" type="button" onClick={handleBroadcastConfirmTabPublishClick}><i className="fas fa-paper-plane"></i>{intl.msg("publish")}</button>
                                          </div>

                                          <div className="mt-3">
                                            <small>
                                              {intl.msg("broadcast_publish_by_clicking")}
                                            </small>
                                          </div>

                                        </div>
                                    ) : (
                                        <>
                                        </>
                                    )
                                  }

                                </div>
                              </PageDialogMainPanel>
                            </>
                        ) : ["PUBLISHED", "GENERATED", "IMPORTED", "FAILED_IMPORT"].includes(broadcast?.status) ? (
                            <>
                              <PageDialogMainPanel title={intl.msg("petsolv_broadcast_publishing_title")} alert={alert}>
                                <div className="mt-3">
                                  {intl.msg("broadcast_publish_thank_you")}
                                </div>
                              </PageDialogMainPanel>
                            </>
                        ) : ["STARTED"].includes(broadcast?.status) ? (
                            <>
                              <PageDialogMainPanel title={intl.msg("petsolv_broadcast_delivering_title")} alert={alert}>
                                <div className="mt-3">
                                  {intl.msg("petsolv_broadcast_delivering_text")}
                                </div>
                                <div className="d-flex justify-content-center mt-3" style={{minWidth: "600px"}}>
                                  <BroadcastSummaryWidgets
                                    broadcastPerformanceStats={broadcast}
                                    showIcons={true}
                                    onResponsesClick={() => openDialog("dlg_responses")}
                                    size="md"/>
                                </div>
                              </PageDialogMainPanel>
                            </>
                        ) : ["ENDED"].includes(broadcast?.status) ? (
                            <>
                              <PageDialogMainPanel title={intl.msg("petsolv_broadcast_completed_title")} alert={alert}>
                                <div className="mt-3">
                                  {intl.msg("petsolv_broadcast_completed_text")}
                                </div>
                                <div className="d-flex justify-content-center mt-3" style={{minWidth: "600px"}}>
                                  <BroadcastSummaryWidgets broadcastPerformanceStats={broadcast} showIcons={true} size="md"/>
                                </div>
                              </PageDialogMainPanel>
                            </>
                        ) : (
                            <>
                              <PageDialogMainPanel title={intl.msg("petsolv_broadcast_view_title")} alert={alert}>
                                {broadcast.broadcastName}
                              </PageDialogMainPanel>
                            </>
                        )
                      }
                    </PageDialog>
                  </PageDialogContainer>
                </>
            ) : (
                <></>
            )
          }
        </MainContainer>
        <ImageDialog
            id="dlg_creativeImagePicker"
            title="Photo of your Pet"
            imageInfo={creativeImageInfo}
            setTargetImageData={handleCreativeImageUrlChange}
            setTouched={setTouched}
        />
        <ImageDialog
            id="dlg_pageImagePicker"
            title="Photo of your Pet"
            imageInfo={pageImageInfo}
            setTargetImageData={handlePageImageUrlChange}
            setTouched={setTouched}
        />
        <ResponsesDialog
          title={intl.msg("responses")}
          id="dlg_responses"
          broadcasts={[broadcast?.broadcastId]}
        />
      </>
  )
}
