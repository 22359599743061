import React, {Fragment, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useSolv, useTenant} from "../components/SolvProvider"
import MainMenu from "../MainMenu"
import {FormHeader, FormBody, MainContainer} from "../components/FormComps.js"
import { useIntlEx } from "../components/IntlUtils"
import {DataTable, useCols} from "../components/TableComps";
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("Industries")

export default function Industries() {

  const {api, session: {user}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const history = useHistory()
  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useState(null)

  useEffect(() => {
    if (tenant) {
      log.debug("Region: tenant=", tenant.accessingAs("ADMIN/*/SYSTEM"))
    }
  }, [tenant])

  const {cols} = useCols({
    resourceName: "industries",
    specs: [
      // {
      //   field: "industryId",
      //   label: intl.msg("industry_id"),
      //   filter: "text",
      //   sort: "ASC",
      //   align: "left",
      // },
      {
        field: "industryName",
        label: intl.msg("industry_name"),
        filter: "text",
        sort: "ASC",
        align: "left",
      },
      {
        field: "iabCategory",
        label: intl.msg("iab_category"),
        filter: {
          type: "asyncselect",
          loader: (input, loadedOptions, additional) => {
            log.debug("listIabCategories: invoked")
            return api.listIabCategories({view: "BASIC", extraParams: `${input ? `filter=iabCategory:${encodeURIComponent(input)}&` : ""}sort=iabCategory:ASC`, cursor: additional?.cursor, limit: 20})
              .then(({data, nextCursor}) => {
                let opt = data.map(c => {
                  return {
                    value: c.iabCategoryId,
                    label: c.iabCategory,
                  };
                });
                let res = {
                  options: opt,
                }
                if (nextCursor) {
                  res = {
                    ...res,
                    hasMore: true,
                    additional: {
                      cursor: nextCursor
                    }
                  }
                }
                log.debug("listIabCategories: res=", res)
                return res
              });
          }
        },
        sort: "",
        align: "left",
      },
    ],
  })

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listIndustries({extraParams: cols.stringify(), cursor: cursor})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "industryId":
        n = <span>{row.industryId}</span>
        break
      case "industryName":
        n = <span>{row.industryName}</span>
        break
      case "iabCategory":
        n = <span>{row.iabCategoryId}</span>
        break
    }
    return (
      n
    )
  }

  function handleRowClick(row) {
    window.location = `/sys/industries/${row.industryId}`
  }

  return (

    tenant &&
    <>

      <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

        <FormHeader>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("industries")}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>

            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>

        <FormBody>

          <DataTable
            tenant={tenant}
            cols={cols}
            resourceName="industries"
            dataKey="industryId"
            onFetchDataPage={handleFetchDataPage}
            onRenderDataCol={handleRenderDataCol}
            // onClickDataRow={handleRowClick}
          />

        </FormBody>
      </MainContainer>
    </>
  )
}