import React, {useState, useEffect, useReducer} from 'react'
import { useParams}  from 'react-router-dom'
import NumericInput from "react-numeric-input";
import {useSolv, useTenant} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {FormHeader, FormBody, MainContainer} from "../components/FormComps.js"
import {validateField, showError, clearAllErrors, InfoBlock, InfoText} from '../components/ValidationUtils.js';
import {simpleReducer} from "../components/ReactUtils";
import MainMenu from "../MainMenu"
import SystemTabs from "./SystemTabs"
import getLogger from "../components/Logging.js"
import '../App.css'
import {default as Select} from "react-select";
import AsyncSelect from "react-select/async";
import {AsyncPaginate} from "react-select-async-paginate";
import {I18nInput, I18nName} from "../components/I18nInput";

const log = getLogger("Industry")

export default function Industry(props) {

  const {api, session: {user}, refreshSignin, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [touched, setTouched] = useState(false)
  const [alert, setAlert] = useState(null)

  const [industryId, setIndustryId] = useState(null)
  const [isAdding, setAdding] = useState(null)
  const [industry, updateIndustry] = useReducer(simpleReducer, {})
  const [iabCategory, setIabCategory] = useState(null)

  useEffect(() => {
    if (params.industryId) {
        setIndustryId(params.industryId)
    }
  }, [])

  useEffect(() => {

    log.debug("useEffect: invoked")

    if (industryId) {
      setBusy(intl.msg("loading"))
      api.getIndustry(industryId)
        .then(({data}) => {
          log.debug(">>>Z: getIndustry: data=", data)
          updateIndustry(data)
          log.debug("getIndustry: data2=", data)
          api.getIabCategory(data.iabCategoryId)
            .then(({data})=> {
              setIabCategory({
                value: data.iabCategoryId,
                label: data.iabCategoryName,
              })
            })
            .catch((error) => {
              log.error(error)
              setFatal(error)
            })
        })
        .catch((error) => {
          log.error(error)
          setFatal(error)
        })
        .finally(() => {
          setBusy(null)
        })
    }

  }, [industryId]);

  function handleIndustryNameChange(v) {
    log.debug("handleIndustryNameChange: ", v)
    updateIndustry({industryName: v})
    setTouched(true)
  }

  function handleIabCategoryChange(v) {
    setIabCategory(v)
    updateIndustry({iabCategoryId: v.value})
    setTouched(true)
  }

  function loadIabCategories(input, loadedOptions, additional) {
    return api.listIabCategories({view: "BASIC", extraParams: `${input ? `filter=iabCategory:${encodeURIComponent(input)}&` : ""}sort=iabCategory:ASC`, cursor: additional?.cursor, limit: 20})
      .then(({data, nextCursor}) => {
        log.debug("loadIabCategories: data=", data)
        let opt = data.map(c => {
          return {
            value: c.iabCategoryId,
            label: c.iabCategoryName,
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadIabCategories: res=", res)
        return res
      });
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_industryName", industry.industryName && Object.values(industry.industryName).some(v => v !== "") , "required", opt)
    validateField("inp_iabCategory", iabCategory, "required", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  async function handleSaveClick() {

    log.debug(">>>Z: handleSaveClick: invoked" )

    if (validate()) {

      setBusy(intl.msg("saving"))

      try {

        const payload = {
          industryName: industry.industryName,
          iabCategoryId: industry.iabCategoryId,
        }

        log.debug(">>>Z: saveIndustry: payload=", payload)

        const {data} = await api.updateIndustry(industryId, payload)

        window.location = `/sys/industries`

      }
      catch (error) {
        log.error(error)
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleCancelClick() {
    window.location.reload()
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
        setAlert({error: intl.msg("error_failed")})
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function isEditable() {
    return user.isSystem() && user.isAdminOrAbove()
  }

  return (
    tenant && industry &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

          <FormHeader>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("industry")} {I18nName(industry.industryName, user.lang)}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  touched &&
                    <>
                      <button key="key_btn_save" className="btn btn-primary" onClick={handleSaveClick}>
                        <i className="fas fa-check"></i>{intl.msg("save")}
                      </button>
                      <button key="key_btn_reset" className="btn btn-secondary" onClick={handleCancelClick}>
                        <i className="fas fa-times"></i>{intl.msg("cancel")}
                      </button>
                    </>
                }
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            <div className="row no-gutters">

              <div className="col">

                <form id="frmMain" autoComplete="off">

                  <div className="form-card">

                    <div className="row">

                      <div className="col-9">

                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label" htmlFor="inp_industryName">{intl.msg("industry_name")}:</label>
                          <div className="col-sm-9">
                            <I18nInput id="inp_industryName" className="form-control" values={industry.industryName} lang={user.lang || "en"} disabled={!isEditable()} onChange={handleIndustryNameChange}/>
                            <InfoBlock>
                              <InfoText>{intl.msg("industry_name_helptext_1")}</InfoText>
                              <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                            </InfoBlock>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label" htmlFor="inp_iabCategory">{intl.msg("iab_category")}:</label>
                          <div className="col-sm-9">
                            <div style={{width: "400px"}}>
                              <AsyncPaginate
                                id="inp_iabCategory"
                                className="react-select"
                                classNamePrefix="react-select"
                                cacheOptions
                                value={iabCategory}
                                debounceTimeout={800}
                                defaultOptions={true}
                                loadOptions={loadIabCategories}
                                onChange={handleIabCategoryChange}
                                isDisabled={!isEditable()}/>
                            </div>
                            <InfoBlock>
                              <InfoText>{intl.msg("iab_category_name_helptext_1")}</InfoText>
                              <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                            </InfoBlock>
                          </div>
                        </div>

                      </div>

                    </div>

                  </div>

                </form>

              </div>

            </div>
          </FormBody>
        </MainContainer>

      </>

  )
}
