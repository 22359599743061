import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from "react"
import { useIntlEx } from "./IntlUtils.js"
import { LOCALES } from "../i18n/locales.js"
import getLogger from "./Logging.js"
import styles from "./I18nInput.module.css"
import useStateRef from "react-usestateref";

const log = getLogger("I18nInput")

export const I18nInput = forwardRef((props, ref)  => {

  const { intl } = useIntlEx()

  // log.debug(">>>Z: init: props=", props, typeof(props.values) === "object" ? props.values.hasOwnProperty(props.lang || "en") ? props.values[props.lang || "en"] : "" : "")

  const [lang, setLang, langRef] = useStateRef(props.lang || "en")
  const [values, setValues, valuesRef] = useStateRef(props.values)
  const [value, setValue, valueRef] = useStateRef(typeof(props.values) === "object" ? props.values.hasOwnProperty(props.lang || "en") ? props.values[props.lang || "en"] : "X1" : "X2")

  const inputRef = useRef()

  useEffect(() => {
    log.debug(">>>Z: useEffect: props=", props)
    if (props.lang && props.values) {
      setLang(props.lang)
      setValues(props.values)
      setValue(typeof (props.values) === "object" ? props.values.hasOwnProperty(props.lang || "en") ? props.values[props.lang || "en"] : "X1" : "X2")
    }
  }, [props]);

  // useEffect(() => {
  //   log.debug(">>>Z: useEffect: props=", props)
  //   if (props.lang) {
  //     setLang(props.lang)
  //   }
  //   if (props.values) {
  //     setValues(props.values)
  //   }
  //   // // if (props) {
  //   //   setValues(props.values ? props.values : {"en": null})
  //   //   setLang(props.lang ? props.lang : "en")
  //   //   setValue(props.values?.hasOwnProperty(props.lang) ? props.values[props.lang] : "")
  //   // }
  // }, [props.values, props.lang])

  // useEffect(() => {
  //   log.debug(">>>Z: useEffect: value=", value)
  //   // if (props) {
  //   //   setValues(props.values ? props.values : {"en": null})
  //   //   setLang(props.lang ? props.lang : "en")
  //   //   setValue(props.values?.hasOwnProperty(props.lang) ? props.values[props.lang] : "")
  //   // }
  // }, [props.value])

  useImperativeHandle(ref, () => ({
    setValue(value) {
      handleValuesChange({target:{value: values}})
      fireEvents({target:{value: values}})
    }
  }));

  function fireEvents(e) {
    if (props.onChange) {
      props.onChange(e)
    }
  }

  function handleValuesChange(e) {
    const v = e.target.value
    log.debug(">>>Z: handleValuesChange: ", v)
    setValues(prev => {
      log.debug(">>>Z: handleValueChange: prev=", prev)
      const x = {...prev, [langRef.current]: v}
      log.debug(">>>Z: handleValueChange: x=", x)
      return x
    })
    setValue(v)
    log.debug(">>>Z: handleValueChange: values=", valuesRef.current)
    if (typeof(props.onChange) === "function") {
      props.onChange(valuesRef.current)
    }
  }

  function handleLangClick(l) {
    log.debug(">>>Z: handleLangClick: ", l)
    setLang(l)
    setValue(valuesRef.current.hasOwnProperty(l) ? valuesRef.current[l] : "")
    inputRef.current.focus()
    // setValue(values[l])
  }

  return (
    <>
      <div className={styles.container} style={{width: props.width ? props.width : "auto"}}>
        {/*<div style={{}}>*/}
          <input ref={inputRef} id={props.id} className={`${styles.input} ${props.className ? props.className : ""}`} key={props.key} type="text" placeholder={props.placeholder} value={value} onChange={handleValuesChange}/>
          <div className={styles.langs}>
            {
              Object.keys(LOCALES).map ((l) => {
                return <button type="button" className={`${styles.lang} ${l === lang ? styles.selected :""} ${values?.hasOwnProperty(l) ? styles.filled : ""}`} onClick={() => handleLangClick(l)}>{l.toLowerCase()}</button>
              })
            }
          </div>
        {/*</div>*/}
      </div>

    </>
  )

})

export function I18nName(name, lang) {
  let n = ""
  if (name) {
    if (name.hasOwnProperty(lang)) {
      n = n[lang]
    }
    else if (name.hasOwnProperty("en")) {
      n = n["en"]
    }
  }
  return (
    <>{n}</>
  )
}